@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-size: 14px;
}

body {
  @apply text-body-text;
  padding: 0;
  margin: 0;
}

html { position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: auto; }
body { width: 100%; height: auto; }